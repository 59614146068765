<template>
  <validation-observer ref="formValidator" v-slot="{ handleSubmit }">
    <form v-on:submit.prevent="handleSubmit(addCustomerApp)" class="p-4">
      <div class="row">
        <div class="col-md-12">
          <div v-if="serverErrors.length">
            <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
              <span v-for="err in serverErrors" :key="err">{{ err }}</span>
            </CAlert>
          </div>
        </div>
        <div class="col-md-3">
          <div class="avatar-container">
            <img
              class="mb-2"
              name="cil-user"
              :src="imageUrl ? imageUrl : getImgUrl('avatar_placeholder.png')"
              alt="Image"
            />

            <validation-observer>
              <ValidationProvider
                ref="photoValidator"
                name="image"
                v-slot="{ errors }"
                vid="image"
                :rules="{ required: false }"
              >
                <button class="btn btn-info" type="button" @click="onPickFile">
                  Browse Customer's Photo
                </button>
                <p class="pt-3 w-100 error-text">* Photo size limit 500KB</p>

                <input
                  name="image"
                  type="file"
                  style="display: none"
                  ref="fileInput"
                  accept="image/png, image/jpeg"
                  @change="onFilePicked"
                />
                <span class="text-danger mt-0 pt-0 error-text">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </validation-observer>
          </div>
        </div>

        <div class="col-md-9">
          <div class="form-group">
            <label for="" class="m-0">Related Attachments</label>
            <small class="d-block">(Use "Ctrl" key to select multiple)</small>
            <input
              multiple="true"
              type="file"
              id="rel_files"
              class="form-control"
              accept="image/png, image/jpeg, application/pdf"
              @change="onFileChange"
            />

            <div class="attachment-area" v-if="related_files.length">
              <div v-for="(file, index) in related_files" :key="index">
                <i class="fa fa-paperclip" aria-hidden="true"></i>
                <span class="project-file-txt">{{ file.name }}</span>
                <a
                  href="javascript:void(0)"
                  class="text-danger"
                  @click="removeFile(index)"
                >
                  <i class="fa fa-times fa-lg" aria-hidden="true"></i>
                </a>
              </div>
            </div>

            <div class="attachment-area" v-if="application.attachments && application.attachments.length">
              <label>Saved Files: </label>
              <div
                v-for="file in application.attachments"
                :key="file.id"
              >
                <i class="fa fa-paperclip" aria-hidden="true"></i>
                <span class="project-file-txt">{{ file.attachment }}</span>
                <a
                  :href="file.attachment_url"
                  download="download"
                  target="_blank"
                  title="Click to download"
                  ><i class="fa fa-download fa-lg" aria-hidden="true"></i
                ></a>

                <a
                  href="javascript:void(0)"
                  class="text-danger"
                  @click="deleteFile(file)"
                >
                  <i class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-default" @click="back()">
            Back
          </button>
          <button type="submit" class="btn btn-primary">
            Submit
          </button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>
<script>
export default {
  name: "Attachments",
  props: {
    application: Object,
    activeTab: Number,
  },
  data() {
    return {
      newApplication: {},
      related_files: [],
      temp_related_files: [],
      related_files_name: [],
      image: null,
      imageUrl: null,
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    back() {
      this.$emit("formSubmitted", {
        data: this.newApplication,
        tab: 2,
      });
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
    },

    getImgUrl(pic) {
      return require("../../../assets/images/" + pic);
    },
    onFileChange(e) {
      this.temp_related_files = Array.from(e.target.files);
      this.related_files = this.related_files.concat(this.temp_related_files);
      document.querySelector("#rel_files").value = "";
    },
    removeFile(index) {
      this.related_files.splice(index, 1);
    },
    deleteFile(attachment) {
      this.$confirm({
        message: `Are you sure, want to delete the attachment?`,
        button: {
          no: "No",
          yes: "Yes, Delete",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store
              .dispatch("CustomerApplication/deleteAttachment", attachment.id)
              .then(
                () => {
                  this.$store.dispatch("Settings/loading", false);
                  this.$toastr.s("Attachment successfuly deleted.", "Deleted!");                                
                },
                (error) => {
                  this.$toastr.e(error.response.data.message, "Failed!");
                  this.$store.dispatch("Settings/loading", false);
                }
              );
          }
        },
      });
    },
    addCustomerApp() {
      let formData = new FormData();
      for (const property in this.newApplication) {
        if (property === "related_fees") {
          formData.append(
            property,
            JSON.stringify(this.newApplication[property])
          );
        } else {
          formData.append(property, this.newApplication[property]?this.newApplication[property]:'');
        }
      }

      this.related_files.forEach((file, key) => {
        formData.append("attachments[" + key + "]", file);
      });

      if (this.image) {
        formData.append("photo", this.image);
      }

      let action = this.newApplication.id?'CustomerApplication/update':'CustomerApplication/store';

      this.$store.dispatch("Settings/loading", true);
      this.$store.dispatch(action, formData).then(
        () => {
          this.$store.dispatch("Settings/loading", false);
          let msg = this.newApplication.id?'Updated':'Created';
          this.$toastr.s(
            "Customer Application Successfuly " + msg,
            "Application " + msg
          );
          this.$router.push("/customer-applications");
        },
        (error) => {
          this.serverErrors = [];
          if (error.response.data) {
            this.serverErrors.push(error.response.data.message);
            this.$toastr.e(error.response.data.message, "Failed!");
            if (error.response.data.errors) {
              this.$refs.formValidator.setErrors(error.response.data.errors);
            }
          }
          this.$store.dispatch("Settings/loading", false);
        }
      );
    },
  },
  mounted() {
    this.newApplication = { ...this.application };
    if(this.newApplication.photo_url) {
      this.imageUrl = this.newApplication.photo_url;
    }
  },
};
</script>
<style lang="scss" scoped>
.attachment-area {
  width: 100%;
  float: left;
  padding: 20px 15px;
  div {
    width: 100%;
    float: left;
    font-size: 14px;
    margin-bottom: 10px;
    i {
      // font-weight: 600;
      font-size: 20px;
      padding-right: 10px;
    }
    span {
      font-size: 14px;
      font-weight: 600;
      margin-right: 10px;
    }
  }
}
.avatar-container {
  width: 100%;
  float: left;
  img {
    max-width: 100%;
    border: 1px dashed #ccc;
  }
}
</style>
